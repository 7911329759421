* {
  font-family: "Segoe UI";
}

.msft-iframe-body {
  background-color: #fff !important;
}

.increase {
  font-size: 40px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    appearance: none;
    margin: 0; 
}
